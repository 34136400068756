<template>

    <div class="article-container row">

        <div class="col-12">

            <div class="row">

                <div class="col-1 left-column">
                <span class="rank">{{ getRank }}</span><br/>
                </div>
                
                <div class="col-10 middle-column">

                    <h3 v-html="highlight(item.title_highlight)"/>

                    <div class="biblio">
                        <p>
                            {{ getId }}.
                            {{ getAuthors }}
                            {{ getDate }}
                            {{ getJournal }}
                            {{ getPublicationTypes }}
                            <a v-for="link in getLinks" :href="link.url" target="_blank" :key="link.url">{{ link.name }} </a>
                        </p>
                    </div>

                    <div class="exclusive" v-if="item.collection_only == true">only in supplementary data</div>

                    <div class="resume" v-if="collection_name!='supp'">
                        <DisplayStatus entity_name="gene" :item="item"/>
                        <DisplayStatus entity_name="variant" :item="item"/>
                        <DisplayStatus entity_name="disease" :item="item"/>
                        <DisplayStatus entity_name="gender" :item="item"/>
                        <DisplayStatus entity_name="age" :item="item"/>
                    </div>
                    <br/>

                    <PanelMoreLess v-if="item.evidences.length>0" :visible_length="3" :items_length="item.evidences.length" message_more="Show more passages" message_less="Show less passages">
                        <template v-slot:default-list>
                            <DisplayEvidence v-for="(evidence, index) in item.evidences.slice(0, 3)" :key="index+'_'+collection_name+'_'+variant" :evidence="evidence" :pmcid="item.id"/>
                        </template>
                        <template v-slot:extra-list>
                            <DisplayEvidence v-for="(evidence, index) in item.evidences.slice(3, item.evidences.length)" :key="index+'_'+collection_name+'_'+variant" :evidence="evidence" :pmcid="item.id"/>
                        </template>
                    </PanelMoreLess>
                    <br/>

                </div>

                <div class="col-1 right-column">

                    <div class="article-menu">

                        <div class="row">
                            <Bookmark :bookmark_init="isInList" :variant="variant" :collection_title="collection_title" :collection_name="collection_name" :doc_id="item.id" :item="item" :position="getRank"/>
                        </div>

                        <div class="row">
                            <span class="score">score<br/>{{ getScore }}</span>
                        </div>

                        <div class="row">
                            <span class="expand">
                                <img src="../assets/images/icon_expand_on.png" alt="[+]" v-show="!expanded_status" @click="expandDoc()"/>
                                <img src="../assets/images/icon_expand_off.png" alt="[-]" v-show="expanded_status"  @click="expandDoc()"/>
                            </span>
                    </div>

                    </div>

                </div>

            </div>

            <div class="row">

                <div v-if="expanded_status" class="more">

                    <div v-if="collection_name=='medline'">
                        <div v-if="item.abstract.length > 0">
                            <label>Abstract</label>
                            <p v-html="highlight(item.abstract_highlight)"></p>
                        </div>
                    <div v-if="item.meshs.length > 0">
                            <label>MeSH terms</label>
                            <p v-html="highlight(item.meshs_highlight)"></p>
                        </div>
                        <div v-if="item.keywords.length > 0">
                            <label>Keywords</label>
                            <p v-html="highlight(item.keywords_highlight)"></p>
                        </div>
                        <div v-if="item.chemicals.length > 0">
                            <label>Chemicals</label>
                            <p v-html="highlight(item.chemicals_highlight)"></p>
                        </div>
                        <div v-if="comments_in in item && item.comments_in.length > 0">
                            <label>Comments in</label>
                            <ul>
                            <li v-for="comment in item.comments_in" :key="comment.id">PMID:{{ comment.id }}. {{ comment.title }} ({{ comment.date }})</li>
                            </ul>
                        </div>
                        <div v-if="comments_on in item && item.comments_on.length > 0">
                            <label>Comments on</label>
                            <ul>
                                <li v-for="comment in item.comments_on" :key="comment.id">PMID:{{ comment.id }}. {{ comment.title }} ({{ comment.date }})</li>
                            </ul>
                        </div>
                    </div>
                    <div v-if="item.details.information_extraction.clinical_trials.length > 0">
                        <label>Clinical trial ID</label>
                        <ul>
                            <li v-for="(ct, index) in getUniqueCT" :key="index">{{ ct.term }}</li>
                        </ul>
                    </div>
                    <div v-if="item.details.information_extraction.populations.length > 0">
                        <label>Size of population</label>
                        <ul>
                            <li v-for="(pop, index) in getUniquePop" :key="index">{{ pop.term }}</li>
                        </ul>
                    </div>
                    <div v-if="collection_name=='ct'">
                        <div v-if="item.abstract.length > 0">
                            <label>Abstract</label>
                            <p v-html="highlight(item.abstract_highlight)"></p>
                        </div>
                        <div v-if="item.detailed_description.length > 0">
                            <label>Detailed description</label>
                            <p v-html="highlight(item.detailed_description_highlight)"></p>
                        </div>
                        <div v-if="item.inclusion_criteria.length > 0">
                            <label>Inclusion criteria</label>
                            <ul>
                                <li v-for="criteria in item.inclusion_criteria_highlight.split('- ').splice(1, item.inclusion_criteria_highlight.split('- ').length)" :key="criteria" v-html="highlight(criteria)"></li>
                            </ul>
                        </div>
                        <div v-if="item.start_date">
                            <label>Start date</label>
                            <p>{{ item.start_date }}</p>
                        </div>
                        <div v-if="item.completion_date">
                            <label>Completion date</label>
                            <p>{{ item.completion_date }}</p>
                        </div>
                        <div>
                            <label>Age</label>
                            <p>{{ item.minimum_age }} - {{ item.maximum_age }}</p>
                        </div>
                        <div>
                            <label>Gender</label>
                            <p>{{ item.gender }}</p>
                        </div>
                    </div>
                    <div v-if="collection_name=='pmc'">
                        <VariomesFullText :pmcid="item.pmcid"/>
                    </div>
                    <div v-if="collection_name=='supp'">
                        <label>Content</label>
                        <p class="long-content" v-html="item.text.replaceAll('\n', '<br/>')"></p>
                    </div>

                </div>
            </div>

        </div>

  </div>

</template>

<script>

    import { mapState } from 'vuex'
    import Bookmark from '@/components/Bookmark.vue'
    import PanelMoreLess from '@/components/PanelMoreLess.vue'
    import DisplayEvidence from '@/components/DisplayEvidence.vue'
    import DisplayStatus from '@/components/DisplayStatus.vue'
    import VariomesFullText from '@/components/VariomesFullText.vue'
    import shared from '@/components/shared.js'

    export default {
      name: 'DisplayDocument',
      components: {
        Bookmark,
        PanelMoreLess,
        DisplayEvidence,
        DisplayStatus,
        VariomesFullText
      },
      props: {
        item: {
            type: Object,
            required: true
        },
        variant: {
            type: String,
            required: true
        },
        collection_name: {
            type: String,
            required: true
        },
        collection_title: {
            type: String,
            required: true
        },
        rank: {
            type: Number,
            default: 0,
        },
        page: {
            type: Number,
            default: 0,
        },
      },
      data (){
        return {
            bookmark_status: false,
            expanded_status: false
        };
      },
      computed: {
        ...mapState(['user_selection']),
        getId(){
            if (this.item.id.includes("NCT")){
                return this.item.id
            }
            else if (this.item.id.includes("PMC")){
                return this.item.id
            }
            else {
                var id = "PMID:"+this.item.id
                if ('pmcid' in this.item){
                    id += ". "+ this.item.pmcid
                }
                return id
            }
        },
        getRank(){
           return ((this.page-1)*10)+(this.rank+1)
        },
        getAuthors() {
            if ('authors' in this.item) {
                if (this.item.authors.length <= 7){
                    return this.item.authors.join(', ')+". "
                }
                else {
                    return this.item.authors.slice(0, 5).join(', ')+ ", et al. "
                }
            }
            else{
                return ""
            }
        },
        getDate() {
            if ('date' in this.item) {
                return this.item.date+". "
            }
            else{
                return ""
            }
        },
        getJournal() {
            if ('journal' in this.item) {
                return this.item.journal+". "
            }
            else{
                return ""
            }
        },
        getPublicationTypes() {
            if ('publication_types' in this.item) {
                return this.item.publication_types.join(', ')+". "
            }
            else{
                return ""
            }
        },
        getLinks(){
            var links = []
            if (this.item.id.includes("NCT")){
                var linkct = {}
                linkct.name = "Clinicaltrials.gov"
                linkct.url = "https://clinicaltrials.gov/ct2/show/"+this.item.id
                links.push(linkct)
            }
            else if (this.item.id.includes("PMC")){
                var linkpmc = {}
                linkpmc.name = "PubMedCentral"
                linkpmc.url = "https://www.ncbi.nlm.nih.gov/pmc/articles/"+this.item.id
                links.push(linkpmc)
                var linkeurope = {}
                linkeurope.name = "EuropePMC"
                linkeurope.url = "https://europepmc.org/article/PMC/"+this.item.pmcid
                links.push(linkeurope)
            }
            else {
                var linkmed = {}
                linkmed.name = "MEDLINE"
                linkmed.url = "https://pubmed.ncbi.nlm.nih.gov/"+this.item.id
                links.push(linkmed)
            }
            if ('pmcid' in this.item){
                if (this.item.pmcid != this.item.id){
                    var linkpmc2 = {}
                    linkpmc2.name = "PubMedCentral"
                    linkpmc2.url = "https://www.ncbi.nlm.nih.gov/pmc/articles/"+this.item.pmcid
                    links.push(linkpmc2)
                    var linkeurope2 = {}
                    linkeurope2.name = "EuropePMC"
                    linkeurope2.url = "https://europepmc.org/article/PMC/"+this.item.pmcid
                    links.push(linkeurope2)
                }
            }
            if (!this.item.id.includes("NCT")){
                var linksibils = {}
                    linksibils.name = "SIBiLS"
                    var docid = this.item.id
                    linksibils.url = "https://biodiversitypmc.sibils.org?id="+docid
                    links.push(linksibils)
            }
            return links
        },
        getUrlName(){
            if (this.item.id.includes("NCT")){
                return "Clinicaltrials.gov"
            }
            else if (this.item.id.includes("PMC")){
                return "PubMed Central"
            }
            else {
                return "MEDLINE"
            }
        },
        getScore() {
            return this.item.score.toFixed(2)
        },
        getUniqueCT(){
            let all_ct = this.item.details.information_extraction.clinical_trials
            let unique = []
            let done = {}
            for (var i=0; i<all_ct.length; i++){
                if (!(all_ct[i].term in done)){
                    done[all_ct[i].term] = ""
                    unique.push (all_ct[i])
                }
            }
            return unique
        },
        getUniquePop(){
            let all_pop = this.item.details.information_extraction.populations
            let unique = []
            let done = {}
            for (var i=0; i<all_pop.length; i++){
                if (!(all_pop[i].term in done)){
                    done[all_pop[i].term] = ""
                    unique.push (all_pop[i])
                }
            }
            return unique
        },
        isInList(){
            if (this.variant in this.user_selection.publications){
                if (this.collection_title in this.user_selection.publications[this.variant]){
                    if (this.item.id in this.user_selection.publications[this.variant][this.collection_title]){
                        return true;
                    }
                }
            }
            return false;
        },
      },
      methods: {
        highlight(text){
            if (text != undefined){
                return(shared.highlight(text, this.user_selection.highlights, this.user_selection.facets))
            }
            else {
                return text
            }
        },
        expandDoc(){
            if (this.expanded_status == false){
                this.$matomo?.trackEvent("Expand document positions for "+this.collection_name, this.getRank);
                this.$matomo?.trackEvent("Expand document", this.collection_name+":"+this.getRank+";"+this.getId);
                this.$matomo?.trackEvent("Relevance by expansion", this.variant+";"+this.collection_name+";"+this.getRank+";"+this.getId);
            }
            this.expanded_status = !this.expanded_status
        }
      },
    }

</script>

<style scoped lang="scss">

    .article-container {
        border: 5px #F2F2F2 solid;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15) unset;
        border-collapse: separate;
        margin: 10px 0px;
        padding: 0px 0px;
    }

    .left-column {
      padding: 10px;
      background-color: #fff;
    }

    .middle-column {
      text-align: justify;
      padding: 0px 20px;
      background-color: #fff;
    }

    .right-column {
        background-color: #F2F2F2;
        text-align: center;
        padding: 0 5px 5px 5px;
    }

    .rank {
        background-color: #A2A2A2;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        min-width: 30px;
        padding: 0;
        border-radius: 20px;
    }

    .exclusive {
        display: inline-block;
        padding: 1px 10px;
        margin-right: 5px;
        margin-bottom: 10px;
        text-align: center;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 0.8em;
        background-color: #70AD47;
    }

    .biblio, .resume, .more {
        font-size: 0.9em;
        text-align: justify
    }

    .more label {
        font-weight: bold;
        text-align: justify
    }

    .article-menu {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .score {
        font-weight: bold;
    }

    .expand {
        width: 100%;
    }

    .expand img {
        width: 20px;
    }

    .expand img:hover {
        cursor: pointer;
    }

    .long-content {
        word-wrap: break-word;
    }

</style>
