<template>

    <div class="evidence">

            <div class="section">{{ evidence.section }}</div>
            <div class="text">
                <div class="long-text" v-html="highlight(evidence.text).replace('\n', '<br/>')"> </div>
                <div v-if="evidence.file">
                    <a :href="update_nlm_url" target="_blank">See file {{ evidence.file }}</a>
                    <span v-if="isDev">
                        <br/><a :href="ark_url" target="_blank"><span class="warning">beta</span> ARK {{ evidence.ARK }}</a>
                    </span>
                </div>
            </div>

    </div>

</template>

<script>

    import { mapState } from 'vuex'
    import shared from '@/components/shared.js'

    export default {
      name: 'DisplayEvidence',
      props: {
        evidence: {
            type: Object,
            required: true
        },
        pmcid:{
            type: String,
            required: true
        }
      },
      computed: {
        ...mapState(['user_selection']),
        ark_url (){
            return "https://n2t.net/ark:/"+this.evidence.ARK
        },
        isDev() {
            if (process.env.NODE_ENV != 'production'){
                return true
            }
            return false
        },
        update_nlm_url(){
            // temp quick fix for NLM update => to be corrected
            var short_pmc = this.pmcid
            short_pmc = short_pmc.replace("PMC", "")
            return "https://pmc.ncbi.nlm.nih.gov/articles/instance/"+short_pmc+"/bin/"+this.evidence.file
        }
      },
      methods: {
        highlight(text){
            return(shared.highlight(text, this.user_selection.highlights, this.user_selection.facets))
        },
    }
    }

</script>

<style scoped lang="scss">

    .section {
        padding: 1px;
        margin-right: 10px;
        text-align: center;
        border-radius: 10px;
        background-color: #aaa;
        min-width: 100px;
        color: #fff;
        float: left;
        height: 100%;
        font-size: 0.8em;
    }

     .evidence {
        font-size: 0.9em;
        margin: 0px 0px 10px 0px;
        width: 100%;
        display: flex;
        align-items:top;
     }
     .long-text {
        word-wrap: break-word;
    }

    .warning {
      padding: 0 10px 0 30px;
      background: url("../assets/images/icon_warning.png") no-repeat 2px 3px;
      background-size: 15px;
      background-color: #FCD299;
      border-radius: 10px;
      color: #000;
    }

</style>
